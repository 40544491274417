import React from 'react';
import './Header.css';
import { FaTwitter, FaTelegramPlane } from 'react-icons/fa';
import dexScreenerIcon from '../assets/dex-screener-seeklogo.svg'; // Import the DexScreener SVG

function Header() {
    return (
        <header>
            <div className="header-container">
                <div className="coin-animation" style={{ background: 'transparent' }}>
                    <video autoPlay loop muted playsInline style={{ background: 'transparent' }} id="header-video">
                        <source src="./assets/spinning_coin.mp4" type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </div>
                <div className="ticker-container">
                    <div className="ticker-text">赵通梗币</div>
                </div>
                <div className="social-icons">
                    <a href="https://twitter.com/your_twitter_handle" target="_blank" rel="noopener noreferrer">
                        <FaTwitter className="social-icon" />
                    </a>
                    <a href="https://t.me/your_telegram_channel" target="_blank" rel="noopener noreferrer">
                        <FaTelegramPlane className="social-icon" />
                    </a>
                    <a href="https://dexscreener.com" target="_blank" rel="noopener noreferrer">
                        <img src={dexScreenerIcon} alt="DexScreener" className="dexscreener-icon" />
                    </a>
                </div>
            </div>
            <h1 className="header-heading">Zhào Dōng</h1>
        </header>
    );
}

export default Header;

