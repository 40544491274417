// Footer.js
import React from 'react';
import './Footer.css';


function Footer() {
    return (
        <footer>
            <img src="./assets/dongzhao32.png" alt="Zhao Dong" className="animated-image2"/>
            <p>© COPYRIGHT 2024. ALL RIGHTS RESERVED BY DONG COIN</p>
            <p>$DONG is a meme coin with no intrinsic value or expectation of financial return. The coin is completely useless and is meant purely for entertainment purposes only.</p>
        </footer>
    );
}

export default Footer;
