// App.js
import React, { useEffect } from 'react';
import CookieConsent from "react-cookie-consent";
import Cookies from 'js-cookie';
import Header from './components/Header';
import Animation from './components/Animation';
import About from './components/About';
import Tokenomics from './components/Tokenomics';
import HowToBuy from './components/HowToBuy';
import Footer from './components/Footer';
import './App.css';

function App() {
    useEffect(() => {
        // Check if the consent cookie is set
        if (Cookies.get('CookieConsent')) {
            console.log('User has consented to cookies');
        } else {
            console.log('User has not consented to cookies');
        }
    }, []);

    return (
        <div className="App">
            <div className="page-container">
                <Header />
                <section id="main-animation">
                    <Animation />
                </section>
                <div className="ticker-container">
                    <div className="ticker-text"></div>
                </div>
                <section className="moving-bar first-bar">$DONG$DONG$DONG$DONG$DONG$DONG$DONG$DONG$DONG$</section>
                <section id="about">
                    <About />
                </section>
                <section id="tokenomics">
                    <Tokenomics />
                </section>
                <section className="moving-bar second-bar">$BUYNOW</section>
                <section id="how-to-buy">
                    <HowToBuy />
                </section>
                <Footer />
                <CookieConsent
                    location="bottom"
                    buttonText="Accept"
                    declineButtonText="Decline"
                    enableDeclineButton
                    style={{ background: "#8B0000", color: "#FFFFFF" }}
                    buttonStyle={{ background: "#FF6347", color: "#FFFFFF", fontSize: "13px", borderRadius: "5px" }}
                    declineButtonStyle={{ background: "#000000", color: "#FFFFFF", fontSize: "13px", borderRadius: "5px" }}
                    expires={150}
                    onAccept={() => {
                        Cookies.set('CookieConsent', 'true', { expires: 150 });
                    }}

                    onDecline={() => {
                        Cookies.remove('CookieConsent');
                    }}
                >
                    This website uses cookies to enhance the user experience.{" "}
                    <span style={{ fontSize: "10px" }}> By clicking "Accept", you consent to the use of all cookies. For more information, please review our <a href="/privacy-policy" style={{ color: "#FFFFFF", textDecoration: "underline" }}>Privacy Policy</a>.</span>
                </CookieConsent>
            </div>
        </div>
    );
}

export default App;
