import React, { useState } from 'react';
import './About.css';

function About() {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);

    return (
        <section id="about">
            <h2>About: Zhao Dong</h2>
            <p>
                Once upon a time in the wild wild west of the early crypto days, there was a brave explorer named Zhao Dong. Our hero ventured deep into the Bitcoin mines, armed only with dreams and a digital wallet.
                <br /><br />
                In 2014, tragedy struck! 🚨 Zhao lost a whopping 9,000 Bitcoin. Yes, you read that right—9,000! That stash wasn't just a few digital coins; it was a treasure chest worth nearly $23.5 million!
                <br /><br />
                Thus was born the legendary crypto cautionary tale. "Getting Zhao Tonged" became the rallying cry for every crypto enthusiast who ever felt the sting of the market's highs and lows. It's a tale of dreams, drama, and digital dollars—
                <br /><br />
                Now, in the spirit of all great memes, comes a token that lets you own a piece of this infamous saga—the Zhao Tonged Coin! 🪙💰  💵💴💶💷💳 = 📈 
                <br /><br />
                Buy it if you dare, and remember—the crypto world is a roller coaster, and every Zhao has his day!
            </p>
            <div className="dd-form-container">
                <h3>在高盛前竖起一根手指！🖕 in front of J.P Morgan</h3>
                <div className="dd-form-image" onClick={openModal}>
                    <img src="./assets/dd200.jpg" alt="Blocked DD Form 214" />
                </div>
            </div>
            {isModalOpen && (
                <div className="modal" onClick={closeModal}>
                    <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                        <span className="close-button" onClick={closeModal}>&times;</span>
                        <img src="./assets/dd200.jpg" alt="Blocked DD Form 214" />
                    </div>
                </div>
            )}
            <div className="images-container">
                <div className="image-wrapper circular">
                    <img src="./assets/dongzhao27.png" alt="Main Coin" className="tossed-coin" />
                </div>
                <div className="horizontal-image-wrapper">
                    <img src="./assets/dongzhao22.png" alt="zhao" className="horizontal-image" />
                </div>
            </div>
        </section>
    );
}

export default About;
