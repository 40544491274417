// HowToBuy.js
import React from 'react';
import './HowToBuy.css';

function HowToBuy() {
    return (
        <section id="how-to-buy">
            <h2>Getting Zhao Tonged</h2>
            <div className="step-container">
                <div className="step">
                    <h3>CREATE A WALLET</h3>
                    <p>
                        Download Phantom or your wallet of choice from the app store or Google Play Store for free. Desktop users, download the Google Chrome extension by going to phantom.app.
                    </p>
                </div>
                <div className="step">
                    <h3>GET SOME SOL</h3>
                    <p>
                        Have SOL in your wallet to switch to $DONG. If you don't have any SOL, you can buy SOL from an exchange or cross-chain swap and send it to your wallet.
                    </p>
                </div>
                <div className="step">
                    <h3>GO TO RAYDIUM, JUPITER...</h3>
                    <p>
                        Connect to Raydium or Jupiter. Go to raydium.io or jup.ag in Google Chrome or on the browser inside your Phantom app. Connect your wallet. Paste the $DONG token address into Raydium or Jupiter and confirm the swap. When Phantom prompts you for a wallet signature, sign.
                    </p>
                </div>
                <div className="step">
                    <h3>SWITCH SOL FOR $DONG</h3>
                    <p>
                        Switch SOL for $DONG. We have ZERO taxes so you don't need to worry about buying with a specific slippage, although you may need to use slippage during times of market volatility.
                    </p>
                </div>
                <div className="socials">
                    <p>JOIN US</p>
                    <div className="icons">
                        <a href="https://telegram.org" target="_blank" rel="noopener noreferrer">
                            <i className="fab fa-telegram"></i>
                        </a>
                        <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
                            <i className="fab fa-twitter"></i>
                        </a>
                        <a href="https://dexscreener.com" target="_blank" rel="noopener noreferrer">
                            <i className="fas fa-chart-bar"></i>
                        </a>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default HowToBuy;
