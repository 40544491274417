// Tokenomics.js
import React, { useState } from 'react';

function Tokenomics() {
    const [address] = useState("COMING SOON");

    const handleCopy = () => {
        navigator.clipboard.writeText(address)
            .then(() => alert('Address copied to clipboard!'))
            .catch(err => alert('Failed to copy address: ', err));
    };

    return (
        <section id="tokenomics">
            <h2>Tokenomics</h2>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px', }}>
                <input type="text" value={address} readOnly style={{ marginRight: '10px', padding: '10px', width: 'auto', flexGrow: 1, border: '0px solid #ccc', borderRadius: '4px', backgroundColor: '#700000', }} />
                <button onClick={handleCopy} style={{ padding: '10px 20px', borderRadius: '12px', cursor: 'pointer', backgroundColor: '#555', }}>Copy Address</button>
            </div>
            <p>It's a pump.fun launch...</p>
        </section>
    );
}

export default Tokenomics;