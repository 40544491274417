import React from 'react';
import './Animation.css';

function Animation() {
    return (
        <section id="animation">
            <img src="./assets/dongzhao23.png" alt="Zhao Dong" className="animated-image"/>
            <h2>Zhao Dong Recounts How He Lost 9,000 BTC</h2>
            <div className="video-container">
                <a href="https://news.bitcoin.com/zhao-dong-recounts-how-he-lost-9000-btc/" target="_blank" rel="noopener noreferrer">
                    Read the story of Zhao Dong's 9,000 BTC loss here!
                </a>
                <div id="video-container">
                    <video id="background-video" autoPlay loop muted playsInline>
                        <source src="./assets/0001.mp4" type="video/mp4"/>
                        Your browser does not support the video tag.
                    </video>
                </div>
            </div>
        </section>
    );
}

export default Animation;
